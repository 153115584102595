.cls-1, .cls-10, .cls-11, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
    stroke: #ffffff;
    stroke-miterlimit: 10;
    stroke-width: 1px;
}

.cls-1 {
    fill: url(#BG-gradient);
}

.cls-2 {
    fill: none;
}

.cls-3 {
    fill: url(#handle-gradient);
}

.cls-4 {
    fill: url(#inox-gradient);
}

.cls-5 {
    fill: url(#glass-gradient);
}

.cls-6 {
    fill: url(#inox-gradient-2);
}

.cls-7 {
    fill: url(#glass-gradient-2);
}

.cls-8 {
    fill: url(#inox-gradient-3);
}

.cls-9 {
    fill: url(#glass-gradient-3);
}

.cls-10 {
    fill: url(#inox-gradient-4);
}

.cls-11 {
    fill: url(#glass-gradient-4);
}
.handle {stroke: #ffffff;}
.cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9, .cls-10, .cls-11, .cls-12, .cls-13, .cls-14, .handle {fill: none;}

.fillBG{
    width: 300px;
}